/**
 * Helper function that retrieves the value of an environmental variable by it's key, attempting to
 * retrieve the value first from the global window object, falling back to the react process object.
 *
 * @param {string} key
 * @returns {*|string|null}
 */
export function getEnv(key) {
    return window.env[`${key}`] || process.env[`${key}`] || null;
}

/**
 * Helper function that parses the provided string, containing an individual's full name,
 * and returns only the capitalized initials of their first and last name.
 *
 * @param {string} fullName
 * @returns {string}
 */
export function getInitials(fullName) {
    const parts = fullName.split(' ');
    let initials = parts[0].substring(0, 1).toUpperCase();

    if (parts.length > 1) {
        initials += parts[parts.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
}
