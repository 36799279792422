import { OktaAuth } from '@okta/okta-auth-js';
import { getEnv } from '../utils/helpers';

/**
 * Security component configuration for Okta.
 *
 * @type {OktaAuth}
 */
const oktaAuth = new OktaAuth({
    clientId: getEnv('REACT_APP_OKTA_CLIENT_ID'),
    issuer: getEnv('REACT_APP_OKTA_ISSUER'),
    redirectUri: `${window.location.origin}/implicit/callback`,
    scopes: ['email', 'groups', 'openid', 'profile'],
    pkce: true,
    tokenManager: {
        storage: 'sessionStorage',
    },
});

export default oktaAuth;
