import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Notification from '@lightbox/kendo-react-toolkit/base/Notification';
import TileLayout from '@lightbox/kendo-react-toolkit/base/TileLayout';
import AppCard from './AppCard';
import { getEnv } from '../utils/helpers';
import ResponsiveContext from '../utils/responsive';

function AppLayout(props) {
    const { applications } = props;
    const { isMobile, isTablet } = useContext(ResponsiveContext);

    const getColumns = () => {
        if (isMobile) return 1;
        if (isTablet && applications.length >= 2) return 2;
        return applications.length;
    };

    const getPositions = () => applications.map((_, index) => {
        const i = index + 1;
        if (isMobile) return { row: i, col: 1 };
        if (isTablet) return { row: Math.ceil(i / 2), col: i % 2 === 0 ? 2 : 1 };
        return { row: 1, col: i };
    });

    return (
        <div id="app-layout">
            <section id="app-layout-body">
                {applications.length > 0 ? (
                    <TileLayout
                        columns={getColumns()}
                        fillMode="outline"
                        items={applications.map((application, index) => ({
                            defaultPosition: { row: 1, col: index + 1 },
                            item: (
                                <AppCard
                                    icon={application.icon}
                                    name={application.name}
                                    url={application.url}
                                />
                            ),
                            reorderable: false,
                            resizable: false,
                        }))}
                        positions={getPositions()}
                        style={{ width: `${applications.length * 30}rem` }}
                        variant="transparent"
                    />
                ) : (
                    <div className="warning">
                        <Notification
                            inline
                            themeColor="light"
                            type={{ style: 'warning', icon: true }}
                        >
                            Looks like you&apos;re not configured with app access.
                            <br />
                            Reach out to&nbsp;
                            <a
                                href={`mailto:${getEnv('REACT_APP_SUPPORT_EMAIL')}?subject=Valuation - Support Request`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {getEnv('REACT_APP_SUPPORT_EMAIL')}
                            </a>
                            &nbsp;to assist.
                        </Notification>
                    </div>
                )}
            </section>
        </div>
    );
}

AppLayout.propTypes = {
    applications: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.arrayOf(PropTypes.string).isRequired,
            name: PropTypes.string.isRequired,
            order: PropTypes.number.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default AppLayout;
