import { getEnv } from '../utils/helpers';

/**
 * Application definitions for the AppLayout component to render individual application cards.
 *
 * @type {{icon: *, name: string. order: number, url: string}[]}
 */
export const APPLICATIONS = {
    'lightbox.cashflow.users': {
        icon: ['fat', 'calculator'],
        name: 'CashFlow',
        order: 3,
        url: getEnv('REACT_APP_CASHFLOW_URL'),
    },
    'lightbox.insights.users': {
        icon: ['fat', 'lightbulb-on'],
        name: 'Insights',
        order: 5,
        url: getEnv('REACT_APP_INSIGHTS_URL'),
    },
    'lightbox.jobs-portal.users': {
        icon: ['fat', 'briefcase'],
        name: 'Jobs',
        order: 1,
        url: getEnv('REACT_APP_JOBS_URL'),
    },
    'lightbox.report-writer.users': {
        icon: ['fat', 'file-signature'],
        name: 'Report Writer',
        order: 4,
        url: getEnv('REACT_APP_REPORT_WRITER_URL'),
    },
    'lightbox.research.users': {
        icon: ['fat', 'map-location-dot'],
        name: 'Research',
        order: 2,
        url: getEnv('REACT_APP_RESEARCH_URL'),
    },
};

/**
 * Converts user groups into application config settings for the AppPicker component
 * @param {Object} user Object containing user groups from Okta
 * @returns {Array} Application config settings
 */
export const getApplications = (user) => user.groups.flatMap((group) => (
    group in APPLICATIONS ? APPLICATIONS[`${group}`] : []
)).sort((a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
});
