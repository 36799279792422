import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import Header from '@lightbox/kendo-react-toolkit/composite/Header';
import Logo from '../images/logo_lightbox_white.png';
import Hero from '../images/hero_city_02.png';
import { getEnv } from '../utils/helpers';
import ResponsiveContext from '../utils/responsive';

function AppHeader(props) {
    const { user } = props;
    const { oktaAuth } = useOktaAuth();
    const { isMobileOrTablet } = useContext(ResponsiveContext);
    const accountSettings = [{
        icon: ['fas', 'users'],
        name: 'Manage My LightBox Account',
        url: getEnv('REACT_APP_OKTA_USER_PROFILE'),
        visible: true,
    }];

    return (
        <div id="app-header-wrapper">
            <Header
                hideLogo
                id="app-header"
                supportMenu={{
                    email: getEnv('REACT_APP_SUPPORT_EMAIL'),
                    emailSubject: getEnv('REACT_APP_SUPPORT_EMAIL_SUBJECT'),
                    link: getEnv('REACT_APP_SUPPORT_URL'),
                    phone: getEnv('REACT_APP_SUPPORT_PHONE_NUMBER'),
                }}
                userAccount={{
                    accountSettings,
                    hideLinkedAccounts: true,
                    onSignOut: () => {
                        oktaAuth.tokenManager.clear();
                        window.location.href = getEnv('REACT_APP_OKTA_LOGOUT_REDIRECT');
                    },
                    user: {
                        email: user.email,
                        name: user.name,
                    },
                }}
            />
            <div className="app-title-wrapper">
                <section className="app-title-section">
                    <div className="app-title-section-div">
                        <img alt="LightBox" src={Logo} style={{ width: isMobileOrTablet ? '35%' : '22%' }} />
                    </div>
                    <div className="app-title-section-div">
                        <h1 id="app-welcome" style={{ fontSize: isMobileOrTablet ? '6vw' : '5vw' }}>
                            Valuation
                        </h1>
                    </div>
                </section>
                <img alt="" src={Hero} style={{ width: '100%', height: 'auto' }} />
            </div>
        </div>
    );
}

AppHeader.propTypes = {
    user: PropTypes.shape({
        email: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
};

export default AppHeader;
