import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useMediaQuery } from 'react-responsive';
import Loader from '@lightbox/kendo-react-toolkit/base/Loader';
import AppHeader from './AppHeader';
import AppLayout from './AppLayout';
import { getApplications } from '../config/applications';
import ResponsiveContext from '../utils/responsive';

function Main() {
    const { authState, oktaAuth } = useOktaAuth();
    const [user, setUser] = useState(null);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1224 });
    const isMobileOrTablet = isMobile || isTablet;
    const viewport = { isMobile, isTablet, isMobileOrTablet };

    useEffect(() => {
        if (!authState.isAuthenticated) {
            // When user is not authenticated, forget any user info
            setUser(null);
        } else {
            oktaAuth.getUser().then((userInfo) => {
                setUser(userInfo);
            });
        }
    }, [authState, oktaAuth]); // Update if authState changes

    if (!user) {
        return (
            <Loader
                size="large"
                style={{ alignItems: 'center', height: '100vh', justifyContent: 'center' }}
                themeColor="primary"
                type="pulsing"
            />
        );
    }

    if (authState.isAuthenticated && user) {
        return (
            <ResponsiveContext.Provider value={viewport}>
                <div id="app-wrapper">
                    <AppHeader user={user} />
                    <AppLayout applications={getApplications(user)} />
                </div>
            </ResponsiveContext.Provider>
        );
    }

    return null;
}

export default Main;
