import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@lightbox/kendo-react-toolkit/base/Button';
import Card, { CardBody } from '@lightbox/kendo-react-toolkit/base/Card';
import Icon from '@lightbox/kendo-react-toolkit/base/Icon';
import ResponsiveContext from '../utils/responsive';

function AppCard(props) {
    const { icon, name, url } = props;
    const { isMobile, isMobileOrTablet } = useContext(ResponsiveContext);

    return (
        <Button
            className="app-card-button"
            fillMode="flat"
            onClick={() => {
                window.open(url, '_blank');
            }}
        >
            <Card className="app-card">
                <CardBody>
                    <Icon
                        icon={icon}
                        style={{
                            height: isMobileOrTablet ? '8vw' : '4vw',
                            width: isMobileOrTablet ? '8vw' : '4vw',
                        }}
                    />
                    <h4
                        className="app-card-title"
                        style={{ fontSize: isMobile ? '0.85rem' : '1.35rem' }}
                    >
                        {name.toUpperCase()}
                    </h4>
                </CardBody>
            </Card>
        </Button>
    );
}

AppCard.propTypes = {
    icon: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default AppCard;
