import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyles from '@lightbox/kendo-react-toolkit/base/GlobalStyles';
import App from './containers/App';
import './styles/main.css';
import './utils/polyfills';
import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <GlobalStyles>
            <Router>
                <App />
            </Router>
        </GlobalStyles>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
